<template>
  <div class="container">
    <div class="bottom-bar">
      <ul class="attention">
        <li>#关注我们</li>
        <li>
          <i class="iconfont icon-weixingongzhonghao"></i>
          <img src="https://oss.qzgjwh.com/webapp/2023/weixin.png"/>
        </li>
        <li>
          <i class="iconfont icon-weixinshipinhao"></i>
          <img src="https://oss.qzgjwh.com/webapp/2023/weixin.png"/>
        </li>
        <li>
          <i class="iconfont icon-xiaohongshu"></i>
          <img src="https://oss.qzgjwh.com/webapp/2023/xiaohongshu.png"/>
        </li>
        <li>
          <i class="iconfont icon-douyin"></i>
          <img src="https://oss.qzgjwh.com/webapp/2023/douyin.png"/>
        </li>
        <li>
          <i class="iconfont icon-kuaishou"></i>
          <img src="https://oss.qzgjwh.com/webapp/2023/kuaishou.png"/>
        </li>
      </ul>

      <div class="declare">
        <div class="nav-bar">
          <ul>
            <li>加入我们</li>
            <li>校园招聘</li>
            <li>社会招聘</li>
            <li>国际招聘</li>
          </ul>
        </div>
        <div class="nav-bar">
          <ul>
            <li>联系我们</li>
            <li>客户服务</li>
            <li>合作洽谈</li>
            <li>地图</li>
          </ul>
        </div>
        <div class="nav-bar">
          <ul>
            <li>法律信息</li>
            <li>服务协议</li>
            <li>隐私政策</li>
            <li>知识产权</li>
          </ul>
        </div>
        <div class="nav-bar">
          <img src="https://oss.qzgjwh.com/webapp/2023/青追国际logo-01_1697538356050.png" alt="青追国际文化"/>
        </div>
      </div>

      <div class="info">
        <div class="website">
          <ul>
            <li>法律声明</li>
            <li>91440300MA5HAW1424</li>
            <li><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022074093</a></li>
          </ul>
        </div>
        <p>Copyright © 2021 - 2023 青追国际文化发展（广州）有限公司. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/icon/iconfont.css'

export default {
  name: "QingFooter",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="less">
.container {
  color: #373838;
  background-color: #fcfcfc;
  // 底部导航栏
  .bottom-bar {
    width: 1200px;
    margin: 0 auto;

    ul, li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    // 订阅号
    .attention {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;
      border-top: 1px solid #f2f3f5;
      border-bottom: 1px solid #f2f3f5;

      li {
        position: relative;
        padding: 0 20px;
        font-size: 1.5rem;

        &:nth-child(1) {
          color: #5d9cf9;
          text-align: left;
          padding-left: 0;
        }

        i {
          font-size: 1.8rem;

          &:hover {
            cursor: pointer;
            color: #5d9cf9;
          }

          &:hover + img {
            display: block;
          }

        }

        img {
          display: none;
          position: absolute;
          width: 180px;
          height: 180px;
          top: -190px;
          border: 1px solid #000;

          animation: rotateInDownLeft;
          animation-duration: 0.2s;

        }
      }

    }

    // 底部导航栏
    .declare {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: left;
      padding: 20px 0;

      .nav-bar {
        flex: auto;

        ul li:first-child {
          font-size: 1.5rem;
          padding-bottom: 10px;
        }

        ul li {
          font-size: 1.2rem;
          padding: 5px;
          height: 100%;
        }

        ul li:not(:first-child):hover {
          cursor: pointer;
        }

      }

      .nav-bar:last-child {
        flex: none;
        width: 400px;
        height: 50px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          margin: auto 0;
        }

      }

    }

    // 企业信息栏
    .info {
      font-size: 1.1rem;

      .website {
        padding-top: 20px;
        border-top: 1px solid #f2f3f5;

        ul {
          display: flex;
          justify-content: center;
          cursor: pointer;

          li {
            padding: 0 10px;
          }

          & > li:last-child a {
            text-decoration: underline;
            color: #0052d9;
          }

        }
      }

      p {
        text-align: center;
        padding-bottom: 30px;
      }
    }

  }

}
</style>